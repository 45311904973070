<template>
  <div class="flex flex-1 w-full backgroud-img">
    <div class="w-full">
      <div class="sticky top-0 z-10">
        <stepper :current-step="3"/>
        <step-progress-bar :step="3"/>
      </div>
      <ValidationObserver
        class="flex flex-col flex-1 px-12 pt-8 pb-8"
        ref="form"
        v-slot="{ invalid, handleSubmit }"
      >
        <form
          @submit.prevent="handleSubmit(nextStep())"
        >
          <div class="flex flex-col my-8">
            <div class="text-2xl font-bold text-jooycar-blue-dark">
              Dirección del propietario
            </div>
          </div>
          <address-input
            v-model="insuredAddress"
            :disable-municipality="true"
            input-id-prefix="insured-address"
          />
          <div class="flex flex-col mt-8 mb-4">
            <div class="text-2xl font-bold text-jooycar-blue-dark">
              Dirección de despacho del dispositivo
            </div>
          </div>
          <div class="flex">
            <label class="block text-center">
              <input
                id="same-address-checkbox"
                class="mr-2 leading-tight"
                type="checkbox"
                v-model="sameAddress"
              >
              <span class="text-sm">
                Usar misma dirección del propietario del vehículo
              </span>
            </label>
          </div>
          <address-input
            v-if="!sameAddress"
            v-model="dispatchAddress"
            input-id-prefix="dispatch-address"
          />
          <div class="flex flex-col my-8">
            <div class="text-2xl font-bold text-jooycar-blue-dark">
              Datos de contacto para inspección
            </div>
          </div>
          <div class="flex flex-col md:flex-row">
            <data-input
              class="flex flex-col flex-1 mt-3 md:mt-0 md:pr-4"
              label="Teléfono Móvil*"
              name="mobilePhone"
              placeholder="12345678"
              text-left="+569"
              v-model="inspectionPerson.mobile"
              rules="required|digits:8"
              :process="value => value.replace(/\D/g,'')"
              :max-length="8"
            />
            <data-input
              class="flex flex-col flex-1 mt-3 md:mt-0 md:pr-4"
              label="Email*"
              name="inspectionEmail"
              placeholder="juanitop@gmail.com"
              v-model="inspectionPerson.email"
              rules="required"
            />
          </div>
          <div class="flex flex-col my-8">
            <div class="text-2xl font-bold text-jooycar-blue-dark">
              Datos adicionales de vehículo
            </div>
          </div>
          <div class="flex flex-col md:flex-row">
            <data-input
              class="flex flex-col flex-1 mt-3 md:mt-0 md:pr-4"
              label="Número de chasis*"
              name="chassisNumber"
              placeholder="AAAAAAAAA11111111"
              :process="value => value.toUpperCase()"
              v-model="insuredVehicle.chassisNumber"
              :tooltip-text="tooltipTexts.chasis"
              rules="required|length:17"
              :max-length="17"
            />
            <data-input
              class="flex flex-col flex-1 mt-3 md:mt-0 md:pr-4"
              label="Número de motor*"
              name="engineNumber"
              placeholder="A11A1111111AA1"
              :process="value => value.toUpperCase()"
              v-model="insuredVehicle.engineNumber"
              :tooltip-text="tooltipTexts.motorNumber"
              rules="required"
              :max-length="30"
            />
          </div>
          <div class="mt-4 text-xs text-center text-jooycar-gray">
            El despacho del dispositivo tienen un costo de $5.500 que se cobrará posterior a la emisión de la póliza
          </div>
          <div class="mt-2 text-xs text-center text-jooycar-gray-dark">
            *Campos obligatorios
          </div>
          <div class="flex flex-col-reverse items-center mt-6 md:flex-row md:justify-center">
            <button
              @click="previousStep"
              type="button"
              class="block w-64 px-4 py-3 mt-2 font-semibold text-center text-white md:mt-auto md:mr-1 bg-jooycar-gray-dark"
            >
              VOLVER
            </button>
            <button
              id="submitBtn"
              class="w-64 px-4 py-3 font-semibold text-white bg-jooycar-blue"
              :class="{
                'opacity-50 cursor-default': invalid,
                'hover:bg-jooycar-blue-dark': !invalid
              }"
              :disabled="invalid"
            >
              QUIERO CONTRATAR
            </button>
          </div>
        </form>
      </ValidationObserver>
      <restart-form-prompt
        class="mb-8"
        v-if="leadError"
      />
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import _pick from 'lodash.pick';
import tooltipTexts from '../helpers/tootltip-texts';
import AddressInput from '../components/address-input';
import DataInput from '../components/data-input.vue';
import Stepper from '../components/stepper';
import StepProgressBar from '../components/step-progress-bar.vue';
import RestartFormPrompt from '../components/restart-form-prompt';

export default {
  components: {
    AddressInput,
    DataInput,
    Stepper,
    RestartFormPrompt,
    StepProgressBar,
  },
  data() {
    return {
      tooltipTexts,
      sameAddress: false,
      insuredPerson: {},
      insuredAddress: {},
      insuredVehicle: {},
      inspectionPerson: {},
      dispatchAddress: {},
      viewStage: 'C2',
    };
  },
  mounted() {
    this.itsMyStage();
  },
  methods: {
    nextStep() {
      this.$analytics.logEvent(
        `${this.$analyticsEvents.custom.nextStage.eventName}_4`,
        {
          name: this.$analyticsEvents.stages.c2
        }
      );
      this.$store.dispatch('updateLeadAndRedirect', { leadToUpdate: this.leadToUpdate, routeName: 'C2' });
    },
    previousStep() {
      this.$analytics.logEvent(
        `${this.$analyticsEvents.custom.previousStage.eventName}_2`,
        {
          name: this.$analyticsEvents.stages.quote
        }
      );
      this.$router.push({ name: 'Quote' });
    },
    itsMyStage () {
      let stageInHistory = false
      const correctStage = this.lead.currentHistory.find(history => history.stage == this.viewStage) > 0;

      this.lead.currentHistory.forEach(history => {
        if (history.stage == this.viewStage) stageInHistory = true;
      });

      if (!correctStage && !stageInHistory) {
        this.$router.push({ name: this.lead.currentStage });
      }
    },
  },
  computed: {
    ...mapState(['leadId', 'lead', 'leadError']),
    ...mapState({
      stages: state => state.lead.stages,
    }),
    leadToUpdate() {
      return {
        ...this.lead,
        currentStage: 'C2',
        stages: {
          ...this.stages,
          insuredPerson: this.insuredPerson,
          insuredVehicle: this.insuredVehicle,
          insuredAddress: this.insuredAddress,
          deliveryAddress: this.sameAddress ? this.insuredAddress : this.dispatchAddress,
          inspectionPerson: this.inspectionPerson,
        },
      };
    },
  },
  watch: {
    stages: {
      immediate: true,
      handler(newVal) {
        this.insuredPerson = { ...newVal.insuredPerson };
        this.insuredAddress = { ...newVal.insuredAddress };
        this.insuredVehicle = { ...newVal.insuredVehicle };
        this.dispatchAddress = { ...newVal.dispatchAddress };
        this.inspectionPerson = {
          ...newVal.inspectionPerson,
          ..._pick(newVal.insuredPerson, ['mobile', 'mobileCode', 'email']),
        };
      },
    },
  },
};
</script>
